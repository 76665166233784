.barchart__item {
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 12px;
    color: #FFF;
}

.barchart__item :last-child {
    text-align: center;
    width: 100%;
}

.barchart__item:first-child {
    border-radius: 8px 0 0 8px;
}

.barchart__item:last-child {
    border-radius: 0 8px 8px 0;
}

.barchart__overlay {
    position: absolute;
    height: 24px;
    fill-opacity: 0;
}