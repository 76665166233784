@tailwind components;

.wc-btn-black[type='button'] {
    @apply bg-black;
}

.wc-btn-brown[type='button'] {
    @apply bg-brown;
}

.wc-btn-white-brown[type='button'] {
    @apply bg-white-brown;
}
.wc-btn-gold[type='button'] {
    @apply bg-gold;
}

.wc-btn-green[type='button'] {
    @apply bg-green;
}

.wc-btn-glacier[type='button'] {
    @apply bg-glacier;
}

.wc-btn-blue[type='button'] {
    @apply bg-blue;
}

.wc-btn-red[type='button'] {
    @apply bg-red;
}

.wc-btn-grey[type='button'] {
    @apply bg-tint-8;
}

.wc-btn-outline[type='button'] {
    @apply bg-transparent text-black;
}

.wc-btn-silver[type='button'] {
    @apply bg-silver-100 text-black px-2 py-1;
}

.wc-btn-orange[type='button'] {
  @apply bg-orange;
}