.barchart__container {
    display: flex;
    flex-direction: row;
    gap: 0 2px;
    width: 100%;
    height: 24px;
    margin-bottom: 16px;
}

.barchart__legend {
    display: flex;
    flex-direction: row;
    gap: 0 16px;
    width: 100%;
    font-size: 12px;
}

.delinquency_overlay {
  background: repeating-linear-gradient(
    -45deg,
    rgba(256, 0, 0, 0.2),
    rgba(256, 0, 0, 0.2) 2px,
    rgba(256, 0, 0, 0.3) 2px,
    rgba(256, 0, 0, 0.3) 4px
  );
}

.delinquent_dot {
  background: repeating-linear-gradient(
    -45deg,
    rgba(256, 0, 0, 0.2),
    rgba(256, 0, 0, 0.2) 2px,
    rgba(256, 0, 0, 0.3) 2px,
    rgba(256, 0, 0, 0.3) 4px
  );
}