@font-face {
  font-family: 'F37Lineca';
  src: url('../../public/fonts/F37LinecaTrial-Thin.otf') format('opentype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'F37Lineca';
  src: url('../../public/fonts/F37LinecaTrial-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'F37Lineca';
  src: url('../../public/fonts/F37LinecaTrial-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'F37Lineca';
  src: url('../../public/fonts/F37LinecaTrial-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'F37Lineca';
  src: url('../../public/fonts/F37LinecaTrial-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'F37Lineca';
  src: url('../../public/fonts/F37LinecaTrial-ExtraBold.otf') format('opentype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'F37Lineca';
  src: url('../../public/fonts/F37LinecaTrial-Black.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}