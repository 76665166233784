.icon-hover:hover {
    @apply cursor-pointer;
    transform: scale(1.5);
    /* text-shadow:3px 6px rgba(255,165,0,.75) */
}

.icon-hover {
    display: flex;
}
.round-button {
    display: flex;
    /* width:80px; */
    /* height:80px; */
    /* line-height:80px; */
    /* border: 2px solid #f5f5f5; */
    border-radius: 50%;
    color:#f5f5f5;
    /* text-align:center; */
    /* align-items: center; */
    justify-content: center;
    justify-items: center;
    align-items: center;
    flex-direction: column;
    text-decoration:none;
    background: #555777;
    box-shadow: 0 0 3px gray;
    /* font-size:20px; */
    width: 2em;
    height: 2em;
    font-weight:bold;
}
.round-button svg {
    display: flex;
    filter: drop-shadow(2px 2px 1px #4444dd);
}
.round-button:hover {
    background: #777555;
}