@import 'fonts.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
    margin: 0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

footer {
    margin-top: auto;
}

html {
    background-color: #FFFEF2;
    font-family: "F37Lineca", sans-serif;
}
.react-aria-ModalOverlay {
    height: var(--visual-viewport-height);
    background: #00000080;
    justify-content: center;
    align-items: center;
    width: 100vw;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
}