.double-item__header {
    display: flex;
    flex-direction: row;
    column-gap: 8px;
    align-items: center;
}

.double-item__dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.double-item__container-inner {
    display: flex;
    flex-direction: row;
    font-size: 10px;
}

.double-item__divider-vertical {
    height: auto;
    margin: 0 16px 0 8px;
    border-left: 1px solid rgb(244 243 232);
}

.double-item__values-container {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    padding-top: 16px;
    font-size: 10px;
}

.double-item__value {
    display: flex;
    justify-content: space-between;
}
